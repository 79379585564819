#poster-container{
  z-index: 4;
  width: 100vw;
  //background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 100%);
  //background: rgba(0,0,0,0.9);
  //background: white;
  min-height: 100vh;
  background: white;

  .reminder{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 64px;
    bottom: 34px;
    color: white;
    font-size: 14px;

    img{
      margin-top: 10px;
      width:19px;
      height: 33px;
    }
  }

  .glass-background{
    background: none;
    border: 1px solid rgba(255,255,255,0.5);

    .detail-value{
      color: white;
    }

    .detail-key{
      color: #959595;
    }
  }

  .bottomContainer{
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    //width: 345px;
    margin: 20px;
    //padding-bottom: 10px;

    .textAndQrcodeContainer{
      z-index: 5;
      display: flex;
      align-items: flex-end;
    }

    .textContainer{
      display: flex;
      position: absolute;
      bottom: 0;
      left: 70px;
      flex-direction: column;
      text-align:left;
      z-index:5;
      color: rgba(16, 16, 16, 0.8);
      margin-left: 10px;
    }
    .qrCode{
      z-index:5;
    }
    //.logo{
    //  z-index:5;
    //  position: absolute;
    //  bottom: 0;
    //  right: 0;
    //  height: 27px;
    //}
  }

  .sub-title-light {
    z-index: 2;
    width: 132px;
    color: rgba(16, 16, 16, 0.8);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 41.68%, rgba(255, 255, 255, 0) 87.5%);
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    //transform: translateX(18%);
    display: flex;
    justify-content: center;
    align-items: center;
    //text-align: center;
  }

  .home-title{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    //transform: translateX(28%);
  }

  .info-container{
    background: rgba(255,255,255,0.5);
    border-radius: 12px;

    .detail-key{
      color:rgba(16, 16, 16, 0.6);
    }

    .detail-value{
      color: rgba(16, 16, 16, 0.8);
    }
  }


}

.stageDiv{
  min-height: 100vh;
  width: 100%;
  padding-bottom: 20px;
  background: rgba(255,255,255,1);
  background-image: url("../../assets/background.png");
  background-size: cover;
  //display: flex;
  //flex-direction: column;

  #title-container{
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //flex-direction: column;
    //width: 100%;
    //margin-left: 50%;

  }
}
